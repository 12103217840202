import React, { useState } from "react";
import { SzAccordion, SzButton, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfiguration, OwnerType } from "../../../../types";
import Switch from "react-switch";
import {
    existingServiceForms,
    TSMD_PORTAL,
    TSMS_PORTAL,
    ODIVEA_PORTAL,
    PHAREENLIGNE_PORTAL,
    REPORT_TRENDS,
    SIGNAL_NOTIFY,
    VA_EXTERNAL_SITE_SIGRENEA,
    VA_EXTERNAL_SIG_SERAMM,
    VA_EXTERNAL_BAIGNADE_WATER_QUALITY,
    VA_EXTERNAL_SYNOPTIQUE_UDB,
    VA_EXTERNAL_SYNOPTIQUE_UDE,
    VA_EXTERNAL_SERAMM_SITE_PUBLICATION,
    VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS,
    VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT,
    VA_EXTERNAL_SITE_MYSIG,
    VA_EXTERNAL_SITE_AQUADVANCED_PLANT,
    VA_EXTERNAL_SITE_AQUADVANCED_UD,
    VA_EXTERNAL_SITE_AQUADVANCED_WW,
    VA_EXTERNAL_SITE_AQUADVANCED_WN,
    VA_EXTERNAL_SITE_AQUACALC,
    VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP,
    VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU,
    KADRAN_PORTAL,
    VA_EXTERNAL_SITE_SHAREPOINT_KADRAN,
} from '../configurationForm';

type PropsType = {
    contractId: string
    ownerId: string,
    selectedServices: string[],
    ownerType: OwnerType,
    serviceConfigurations: { [key: string]: any },
    setServiceConfigurations: (serviceConfigurations: { [key: string]: ApplicationConfiguration }) => void,
    parentConfigurations?: { [serviceCode: string]: string },
    goToPrev: () => void,
    onValidate: () => void,
    isSaving: boolean
}

const Step2 = (props: PropsType) => {
    const {
        contractId,
        selectedServices,
        serviceConfigurations,
        setServiceConfigurations,
        ownerId,
        ownerType,
        parentConfigurations,
        goToPrev,
        onValidate,
        isSaving
    } = props;
    const serviceFormComponents = {
        TSMS_PORTAL: TSMS_PORTAL,
        TSMD_PORTAL: TSMD_PORTAL,
        ODIVEA_PORTAL: ODIVEA_PORTAL,
        PHAREENLIGNE_PORTAL: PHAREENLIGNE_PORTAL,
        REPORT_TRENDS: REPORT_TRENDS,
        SIGNAL_NOTIFY: SIGNAL_NOTIFY,
        VA_EXTERNAL_SITE_SIGRENEA: VA_EXTERNAL_SITE_SIGRENEA,
        VA_EXTERNAL_SIG_SERAMM: VA_EXTERNAL_SIG_SERAMM,
        VA_EXTERNAL_BAIGNADE_WATER_QUALITY: VA_EXTERNAL_BAIGNADE_WATER_QUALITY,
        VA_EXTERNAL_SYNOPTIQUE_UDB: VA_EXTERNAL_SYNOPTIQUE_UDB,
        VA_EXTERNAL_SYNOPTIQUE_UDE: VA_EXTERNAL_SYNOPTIQUE_UDE,
        VA_EXTERNAL_SERAMM_SITE_PUBLICATION: VA_EXTERNAL_SERAMM_SITE_PUBLICATION,
        VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS: VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS,
        VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT: VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT,
        VA_EXTERNAL_SITE_MYSIG: VA_EXTERNAL_SITE_MYSIG,
        VA_EXTERNAL_SITE_AQUADVANCED_PLANT: VA_EXTERNAL_SITE_AQUADVANCED_PLANT,
        VA_EXTERNAL_SITE_AQUADVANCED_UD: VA_EXTERNAL_SITE_AQUADVANCED_UD,
        VA_EXTERNAL_SITE_AQUADVANCED_WW: VA_EXTERNAL_SITE_AQUADVANCED_WW,
        VA_EXTERNAL_SITE_AQUADVANCED_WN: VA_EXTERNAL_SITE_AQUADVANCED_WN,
        VA_EXTERNAL_SITE_AQUACALC: VA_EXTERNAL_SITE_AQUACALC,
        VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP: VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP,
        VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU: VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU,
        KADRAN_PORTAL: KADRAN_PORTAL,
        VA_EXTERNAL_SITE_SHAREPOINT_KADRAN : VA_EXTERNAL_SITE_SHAREPOINT_KADRAN
    }
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState<string>("-1");

    const onAccordionClick = (key: string) => setActiveKey(key !== activeKey ? key : "-1");

    const onAccordionSwitchClick = (serviceName: string) => {
        let tmpConf: ApplicationConfiguration = serviceConfigurations[serviceName];

        if (ownerType === "CONTRACT") {
            tmpConf.forceChildConfiguration = !tmpConf.forceChildConfiguration;
        }
        if (ownerType === "ORGANIZATION") {
            tmpConf.useParentConfiguration = !tmpConf.useParentConfiguration;
            if (tmpConf.useParentConfiguration && parentConfigurations && typeof parentConfigurations[serviceName] !== "undefined") {
                tmpConf.parentConfiguration = parentConfigurations[serviceName];
            } else {
                delete tmpConf.parentConfiguration;
            }
        }

        setServiceConfigurations({ ...serviceConfigurations, [serviceName]: tmpConf });
    }

    const getFormComponent = (serviceName: string) => {
        if (existingServiceForms.indexOf(serviceName) !== -1) {
            const FormComponent = serviceFormComponents[serviceName as keyof typeof serviceFormComponents];

            return <FormComponent
                contractId={contractId}
                owner={ownerId}
                ownerType={ownerType as OwnerType}
                serviceConfigurations={serviceConfigurations}
                setServiceConfigurations={setServiceConfigurations}
            />
        }
        return null;
    }

    const generateAccordionHeader = (serviceName: string,) => <div className={"d-flex justify-content-between px-2"}>
        <SzTypographie variant={"h2"} className={"pr-4"}>
            {t(`admin.services.configuration.form.${serviceName}`)}
        </SzTypographie>
        {ownerType === "CONTRACT"
            && typeof serviceConfigurations[serviceName] !== "undefined"
            && serviceConfigurations[serviceName].displayButtonForceChildConfiguration !== false
            && <div className={"d-flex"}>
            <Switch checked={serviceConfigurations[serviceName].forceChildConfiguration ?? false}
                    onChange={() => onAccordionSwitchClick(serviceName)} />
            <SzTypographie variant={"body"} className={"pl-2"}>
                {t(`admin.services.configuration.form.forceChildConfiguration`)}
            </SzTypographie>
            </div>
        }
        {ownerType === "ORGANIZATION"
        && typeof serviceConfigurations[serviceName] !== "undefined"
        && typeof parentConfigurations !== "undefined"
        && typeof parentConfigurations[serviceName] !== "undefined"
        && <div className={"d-flex"}>
            <Switch checked={serviceConfigurations[serviceName].useParentConfiguration ?? false}
                    onChange={() => onAccordionSwitchClick(serviceName)}/>
            <SzTypographie variant={"body"} className={"pl-2"}>
                {t(`admin.services.configuration.form.useParentConfiguration`)}
            </SzTypographie>
        </div>
        }
    </div>;

    const formsRendered = selectedServices.map((service: string, index: number) => {
        const ServiceForm = getFormComponent(service);
        if (ServiceForm) {
            const header = generateAccordionHeader(service)
            const form = <div key={index} className={"p-4"}>
                {ServiceForm}
            </div>;

            return <SzAccordion.SzCollapse
                key={index}
                header={header}
                isDisabled={typeof serviceConfigurations[service] !== "undefined" && (serviceConfigurations[service].useParentConfiguration === true || serviceConfigurations[service].forceChildConfiguration === true)}
                eventKey={index.toString()}
                onClick={() => onAccordionClick(index.toString())}
                body={form}
                message={t('admin.services.configuration.form.formDisabled')}
            />
        }
    });

    const noFormComponent = <div className={"p-5"}>
        <SzTypographie>
            {t('admin.services.configuration.form.noForm')}
        </SzTypographie>
    </div>

    return <>
        <SzAccordion activeKey={activeKey}>
            {formsRendered.filter((n) => n).length > 0 ? formsRendered : noFormComponent}
        </SzAccordion>
        <div className={"d-flex justify-content-between p-2"}>
            <SzButton onClick={goToPrev}>
                {t('previous')}
            </SzButton>
            <SzButton onClick={onValidate} loader={isSaving} isDisabled={isSaving}>
                {t('validate')}
            </SzButton>
        </div>
    </>;
}

export default Step2;
