import { Families, Roles, widgetTypes } from "./index";
import { ServiceWidgetMapping } from "../types";

//size:  2 = one-h, 4 = two-h , 3=one-l , 6= two-l
const signalWidgets = {
    [widgetTypes.SIGNAL_STAT_FULL]: {  // Suivi signalement
        path: 'signalFull',
        family: Families.SIGNAL,
        size: { x: 0, y: 0, h: 2, w: 6 },
        roles: [Roles.ROLE_SIGNAL_NOTIFY],
        class:'one-h'
    },
    [widgetTypes.SIGNAL_STAT_CONDENSED]: { // Suivi signalement (nouveaux)
        path: 'signalCondensed',
        family: Families.SIGNAL,
        size: { x: 0, y: 0, h: 2, w: 3 },
        roles: [Roles.ROLE_SIGNAL_NOTIFY],
        class:'one-h'
    },
    [widgetTypes.SIGNAL_TOP_CATEGORY]: { // signalement widget top 5
        path: 'signalTopCategory',
        family: Families.SIGNAL,
        size: { x: 0, y: 0, h: 4, w: 3 },
        roles: [Roles.ROLE_SIGNAL_NOTIFY],
        class:'two-h'
    },
};

const documentWidgets = {
    [widgetTypes.LAST_DOCUMENTS]: { // nouveaux documents
        path: 'LastDocuments',
        family: Families.DOCUMENT,
        size: { x: 0, y: 0, h: 4, w: 6 },
        roles: [Roles.ROLE_VA_GEST_DOC],
        class:'two-h'
    },
    [widgetTypes.DOCUMENT_NOTIFICATION]: {  // notifications
        path: 'DocumentNotifications',
        family: Families.DOCUMENT,
        size: { x: 0, y: 0, h: 4, w: 6 },
        roles: [Roles.ROLE_VA_GEST_DOC],
        class:'two-h'
    },
    [widgetTypes.NUMBER_DOCUMENTS]: { //  nombre de document
        path: 'NumberDocuments',
        family: Families.DOCUMENT,
        size: { x: 0, y: 0, h: 2, w: 3 },
        roles: [Roles.ROLE_VA_GEST_DOC],
        class:'one-h'
    },
};

const waterWidgets = {
    [widgetTypes.HYDROMETRIC]: {
        path: 'hydrometric',
        family: Families.HYDROMETRIC,
        size: { x: 0, y: 0, h:4 , w: 6 },
        roles: [Roles.ROLE_HYDRO],
        class:'two-h'
    },
    [widgetTypes.WATER_CYCLE]: { //  cyle de l'eau widget
        path: 'water-cycle',
        family: Families.WATER_CYCLE,
        size: { x: 0, y: 0, h: 2, w: 3 },
        roles: [Roles.ROLE_OPOT],
        class:'one-h'
    },
    [widgetTypes.WATER_CONFORMITY]: {  // Analyse conformité eau potable
        path: 'waterConformity',
        family: Families.WATER_CYCLE,
        size: { x: 0, y: 0, h: 2, w: 3 },
        roles: [Roles.ROLE_OPOT],
        class:'one-h'
    },
};

const travauWidgets = {
    [widgetTypes.PUBLIC_SPACE_PLANNER_STAT_FULL]: {
        path: 'publicSpacePlannerFull',
        family: Families.PUBLIC_SPACE_PLANNER,
        size: { x: 0, y: 0, h: 2, w: 6 },
        roles: [Roles.ROLE_PUBLIC_SPACE_PLANNER],
        class:'one-h'
    },
};

const requestWidgets = {
    [widgetTypes.REQUEST_STATUS]: {
        path: 'requestStatus',
        family: Families.REQUEST_STATUS,
        size: { x: 0, y: 0, h: 2, w: 6 },
        roles: [Roles.ROLE_REQUEST],
        class:'one-h'
    },
};


const InterventionWidgets = {
    [widgetTypes.INTERVENTIONS]: {
        path: 'ongoingInterventions',
        family: Families.INTERVENTIONS,
        size: { x: 0, y: 0, h: 2, w: 3 },
        roles: [Roles.ROLE_ITV],
        class:'one-h'
    },
}

const serviceWidgetMapping: ServiceWidgetMapping = {
    ...documentWidgets,
    ...InterventionWidgets,
    ...travauWidgets,
    ...waterWidgets,
    ...signalWidgets,
    ...requestWidgets
}

export default serviceWidgetMapping;
