import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { SzIcon, SzTable } from "@suezenv/react-theme-components";
import { Area, Contract } from "../../../../types";
import TabHeader from "../../adminTabs/tabHeader";
import { AreaService } from "../../../../services/AreaService";
import { useLocation } from "react-router-dom";
import { CommonHelper } from "../../../../helper/Common";
import { AppAdminDetailsUrls } from "../../../../constants";
import { connect } from "react-redux";
import { displayMessageIfEmptyTab } from 'components/admin/adminTabs/common';

type AreaData = {
    actions: JSX.Element,
    exploitant: string,
    label: string,
    organization: string,
    skillSet: string,
}

type IAdminUserAreas = {
    userId: string,
    contractSelected?: Contract,
    currentContract: Contract,
    readonly?: boolean
}

const AdminUserAreas = (props: IAdminUserAreas) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { userId, currentContract, contractSelected, readonly } = props;
    const usedContract = contractSelected ?? currentContract;
    const [areasData, setAreasData] = useState<AreaData[]>([]);

    const fetchAreasByUser = () => AreaService.getUserAreas(userId);
    const {
        data: userAreas,
        isSuccess
    } = useQuery(['userAreas', userId], fetchAreasByUser);

    useEffect(() => {
        if (isSuccess) {
            const areas: Area[] = userAreas?.data;
            let areasData: AreaData[] = [];
            areas.forEach((area: Area) => areasData.push(formatData(area)));
            setAreasData(areasData);
        }
    }, [userAreas]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.area.label')
        },
        {
            dataField: 'skillSet',
            text: t('admin.area.skillSet')
        },
        {
            dataField: 'exploitant',
            text: t('admin.area.exploitant')
        },
        {
            dataField: 'organization',
            text: t('admin.area.organization')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToContractAreaDetails = (contractId: string, areaId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.AREA_DETAILS)
            .replace(':contractId', contractId)
            .replace(':areaId', areaId)
        );
    }

    const formatData = (area: Area): AreaData => {
        return {
            label: area.name ?? (area.label ?? ""),
            exploitant: "0",
            organization: "0",
            skillSet: "0",
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                             onClick={() => goToContractAreaDetails(usedContract.id, area.id)}/>
        }
    };

    return <div className="box">
        <TabHeader title={t('admin.tabs.areas')}/>
        {areasData && <SzTable
            data={areasData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {displayMessageIfEmptyTab(areasData, t)}
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminUserAreas);
