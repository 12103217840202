import axiosService from './Request';
import { ApiUrls } from '../constants';
import { store } from "../store";
import { skillsData } from "../actions";

export class SkillSetService {
    public static getAll(filters?: string[], noParent?: boolean) {
        return axiosService.getWithParams(ApiUrls.SKILLSETS, { query: filters, noParent: noParent });
    }

    public static initUserSkills() {
        axiosService.get(ApiUrls.USER_SKILLS, false, true)
            .then(response => store.dispatch(skillsData(response.data)))
            .catch(() => store.dispatch(skillsData({ 'error': true })));
    }
}
