import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ApplicationConfigurationFormProps } from "../../../../types";
import { SimpleConfiguration } from "./SimpleConfiguration";

const serviceName = "TSMS_PORTAL";


export const TSMS_PORTAL = (props: ApplicationConfigurationFormProps) => {
    return <SimpleConfiguration {...props} serviceName={serviceName} />;
}
