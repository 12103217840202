import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import userReducer from "./user_reducer";
import loadingReducers from "./loading_reducer";
import interventionReducers from "./interventions_reducer";
import errorReducers from "./error_reducer";
import uiReducer from "./ui_reducer";
import contractReducer from "./contract_reducer";
import permissionsReducer from "./permissions_reducer";
import skillsReducer from "./skills_reducer";
import themeReducer from "./theme_reducer";
import user_configuration_reducer from "./user_configuration_reducer";
import industrialSiteReducer from "./industrial_site_reducer";

const rootReducer = (history: History) => combineReducers({
    contract: contractReducer,
    error: errorReducers,
    industrialSite: industrialSiteReducer,
    intervention: interventionReducers,
    loading: loadingReducers,
    permissions: permissionsReducer,
    router: connectRouter(history),
    skills: skillsReducer,
    theme: themeReducer,
    ui: uiReducer,
    user: userReducer,
    userConfiguration: user_configuration_reducer,
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
