import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfigurationFormProps } from "../../../../types";

type ApplicationParameters = {
    btnLabel: string
};

interface SimpleConfigurationProps extends ApplicationConfigurationFormProps {
    serviceName: string;
}


export const SimpleConfiguration = (props: SimpleConfigurationProps) => {
    const { serviceConfigurations, setServiceConfigurations, owner, ownerType, serviceName } = props;
    const { t } = useTranslation();
    const [btnLabel, setBtnLabel] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.btnLabel ?? "" : "");

    const parameters = useRef<ApplicationParameters>({
        btnLabel: btnLabel
    });

    useEffect(() => {
        parameters.current = {...parameters.current, btnLabel }
            if (
                "undefined" === typeof serviceConfigurations[serviceName] ||
                "undefined" !== typeof serviceConfigurations[serviceName] &&
                parameters.current.btnLabel !== serviceConfigurations[serviceName].parameters?.btnLabel
            ) {
                setServiceConfigurations({
                    ...serviceConfigurations, [serviceName]: {
                        displayButtonForceChildConfiguration: true,
                        name: serviceName,
                        owner: owner,
                        ownerType: ownerType,
                        parameters: {
                            btnLabel: parameters.current.btnLabel
                        }
                    }
                });
            }
    }, [btnLabel]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBtnLabel(event.target.value);
    };

    return <div>
        <SzInput
            value={btnLabel}
            name={`${serviceName}.btnLabel`}
            onChange={handleInputChange}
            label={t('admin.services.configuration.form.btnLabel')}
            required={true}
        />
    </div>

}
