import React, {useRef} from 'react';
import ReactDOMServer from "react-dom/server";
import {useQuery} from "react-query";
import {useTranslation} from "react-i18next";
import {FeatureGroup, Polygon, TileLayer, ZoomControl} from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen-plugin";
import {OpenStreetMapProvider, SearchControl} from "react-leaflet-geosearch";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import L from "leaflet";
import {SzButton, SzData, SzIcon, SzSpinner, SzTypographie} from "@suezenv/react-theme-components";
import {adminTabsEventKeys, Map} from "../../../../constants";
import {Area, Contract, UserHeimdall} from "../../../../types";
import MapComponent from "../../../map";
import {UsersService} from "../../../../services/UsersService";
import AdminTabs from "../../adminTabs";
import {CommonHelper} from "../../../../helper/Common";
import {v4 as uuidv4} from "uuid";
import {AreaService} from "../../../../services/AreaService";
import {ContractsService} from "../../../../services/ContractsService";
import './details.scss';

// Fix missing search marker icon in current leaflet v3
const DefaultIcon = L.icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});
L.Marker.prototype.options.icon = DefaultIcon;

const AdminUserDetails = (props: { userId?: string, contractId?: string }) => {
    const history = useHistory();
    const location = useLocation();
    const {contractId, userId} = useParams();
    const currentUserId = userId ?? props.userId;
    const currentContractId = contractId ?? props.contractId;
    const {t} = useTranslation();
    const featureGroupRef: any = useRef(null);

    const tabsDisplayed = [
        adminTabsEventKeys.USER_ORGANIZATIONS,
        adminTabsEventKeys.USER_OPERATORS,
        adminTabsEventKeys.USER_AREAS,
        adminTabsEventKeys.USER_CONTRACTS,
    ];

    const fetchUser = () => UsersService.getUser(currentUserId, currentContractId);
    const {
        data: user,
        isLoading
    } = useQuery<UserHeimdall>(['user', currentUserId, currentContractId], fetchUser);

    const fetchUserOrganizationsAreas = () => AreaService.getUserAreas(currentUserId);
    const {
        data: areas
    } = useQuery(['userAreas', currentUserId], fetchUserOrganizationsAreas);

    const fetchContract = () => ContractsService.getContractById(currentContractId);
    const {data: contractDetails} = useQuery<Contract>(['contract', currentContractId], fetchContract);

    const backToContract = (contractId: string) => history.push(
        CommonHelper.backToContractsRoute(location.pathname, adminTabsEventKeys.CONTRACT_USERS).replace(':contractId', contractId)
    );
    const displayMap = () => {
        if (!areas) {
            return <></>;
        }

        const zooms = {
            zoom: Map.ZOOM_DEFAULT,
            minZoom: Map.ZOOM_MIN,
            maxZoom: Map.ZOOM_MAX,
        };

        return <MapComponent
            className="map-container map-area"
            zooms={zooms}
            zoomControl={false}
            refs={featureGroupRef}
        >
            <TileLayer
                url={Map.OSM_URI.toString()}
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {areas &&
                <FeatureGroup ref={featureGroupRef} key={uuidv4()}>
                    {areas?.data?.map((area: Area, key: number) => {
                        if (area.geoJson) {
                            const coordinates = CommonHelper.coordsToLatLngs(area.geoJson);
                            if (!coordinates) {
                                return null;
                            }
                            return <Polygon key={key} className="area-polygon" positions={coordinates}/>
                        }

                        return <></>
                    })}
                </FeatureGroup>
            }
            <SearchControl
                position="topright"
                provider={OpenStreetMapProvider()}
                searchLabel={t('search_address')}
                keepResult={true}
                marker={DefaultIcon}
            />

            <ZoomControl
                position="bottomright"
                zoomInTitle={t('map.zoom_in')}
                zoomInText={ReactDOMServer.renderToString(
                    <SzIcon
                        variant="bold"
                        icon="zoom-in-1"
                        className="control-icon"
                    />
                )}
                zoomOutTitle={t('map.zoom_out')}
                zoomOutText={ReactDOMServer.renderToString(
                    <SzIcon
                        variant="bold"
                        icon="zoom-out-1"
                        className="control-icon"
                    />
                )}
            />

            <FullscreenControl
                position="bottomright"
                title={t('map.fullscreen')}
                titleCancel={t('map.fullscreen_cancel')}
                content={ReactDOMServer.renderToString(
                    <SzIcon
                        variant="bold"
                        icon="resize-expand-corner"
                        className="control-icon"
                    />
                )}
            />
        </MapComponent>;
    }

    return <>
        {isLoading && <SzSpinner/>}
        {user && <>
            {!props.userId &&
                <div className="row">
                    <div className="col">
                        <SzButton variant={"tertiary"} icon={"keyboard-arrow-left"}
                                  onClick={() => backToContract(currentContractId)}>
                            {t("admin.back_to_contract")}
                        </SzButton>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col">
                    <SzTypographie className={"mt-3"} variant={"h1"}>
                        {t("admin.users.detail", {'userFirstName': user.firstName, 'userLastName': user.lastName})}
                    </SzTypographie>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="box">
                        <div className="row">
                            <div className="col">
                                {t('admin.users.about')}
                            </div>
                            <div className="text-right">
                                <SzIcon variant="line" icon="pencil-write"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="box box-no-shadow box-dark">
                                    <div className="row">
                                        <div className="col">
                                            <SzData className="mx-4">
                                                <SzData.Text>{t("admin.users.identity")}</SzData.Text>
                                                <SzData.Title>{user.lastName ? user.lastName : "-"} {user.firstName ? user.firstName : '-'}</SzData.Title>
                                            </SzData>
                                        </div>
                                        <div className="col">
                                            <SzData className="mx-4">
                                                <SzData.Text>{t("admin.users.profile")}</SzData.Text>
                                                <SzData.Title>{user.type ? user.type : '-'}</SzData.Title>
                                            </SzData>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mt-4">
                                            <SzData className="mx-4">
                                                <SzData.Text>{t("admin.users.emailAddress")}</SzData.Text>
                                                <SzData.Title>{user.username ? user.username : '-'}</SzData.Title>
                                            </SzData>
                                        </div>
                                        <div className="col mt-4">
                                            <SzData className="mx-4">
                                                <SzData.Text>{t("admin.users.phone")}</SzData.Text>
                                                <SzData.Title>{user.mobilePhone ? user.mobilePhone : '-'}</SzData.Title>
                                            </SzData>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                {displayMap()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div className="admin-tabs">
                    <AdminTabs
                        tabsDisplayed={tabsDisplayed}
                        userId={currentUserId}
                        contractSelected={contractDetails}
                        readonly={!!props.userId}
                    />
                </div>
            </div>
        </>}
    </>;
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    contractId: state.contract.currentContract.id
});

export default connect(mapStateToProps)(AdminUserDetails);
