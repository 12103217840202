import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SzDualListBox, { SzDualListBoxOption } from "../../../elements/SzDualListBox";
import { AreaService } from "../../../../services/AreaService";
import { useQuery } from "react-query";
import { Area, Organization } from "../../../../types";
import { SzButton } from "@suezenv/react-theme-components";
import { OrganizationService } from "../../../../services/OrganizationService";
import successPicture from "../../../../assets/img/contract/creation_success.svg";
import FormSuccessPage from "../../FormSuccessPage";

type PropsType = {
    organization: Organization,
    refetchAreas: () => void;
}

const AdminOrganizationAddArea = (props: PropsType) => {
    const { organization, refetchAreas } = props
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const fetchClientAreas = () => {
        return AreaService.getClientAreas(organization.contractId, 1, 9999);
    }

    const { data: areaData } = useQuery(["clientAreas", organization.contractId], fetchClientAreas);

    const areaDataToOption = (areas: Area[]) => {
        return areas.map((area: Area) => {
            return { id: area.id as string, label: area.label } as SzDualListBoxOption;
        }).sort((a: SzDualListBoxOption, b: SzDualListBoxOption) => {
            return a.label.localeCompare(b.label);
        });
    }

    const filterAreaOptions = (filter: string) => {
        if (areaData) {
            setOptions(areaDataToOption(areaData.data).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(() => {
        if (areaData) {
            const tmpOptions = areaData.data.map((area: Area) => {
                return { id: area.id as string, label: area.label }
            }).filter((area: Area) => {
                return !organization.area.find((organizationArea: {id: string, label:string}) => organizationArea.id === area.id);
            })
            setOptions(tmpOptions);
        }
    }, [areaData, organization]);

    const onValidate = () => {
        setIsSaving(true);
        const selectedAreas = selectedOptions.map((value: SzDualListBoxOption) => value.id);
        const currentAreas = organization.area.map((area) => area.id);
        OrganizationService.updateOrganizationAreas(organization.id, selectedAreas.concat(currentAreas))
            .then(() => {
                refetchAreas();
                setIsSuccess(true);
            })
            .finally(() => setIsSaving(false));
    }

    const resetForm = () => {
        setSelectedOptions([]);
        setIsSuccess(false)
    }

    const formComponent = <>
            <SzDualListBox
                options={options}
                selectedOptions={selectedOptions}
                showRowIcons={true}
                onlySelectWithRowIcons={true}
                leftSideTitle={t("admin.organization.modal.add.allArea")}
                rightSideTitle={t("admin.organization.modal.add.selectedArea")}
                onChange={setSelectedOptions}
                onKeywordsChange={filterAreaOptions}
                searchPlaceholder={t('search')}
                isScrollable={true}
            />
            <div className={"text-right"}>
                <SzButton onClick={onValidate} loader={isSaving}>
                    {t('validate')}
                </SzButton>
            </div>
        </>

    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t('admin.organization.organizationArea.modal.area_added')}
        subtitle={t('admin.organization.organizationArea.modal.area_added_subtext')}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t('admin.organization.organizationArea.modal.add_new_area')}
    />

    return isSuccess ? successComponent : formComponent;
}

export default AdminOrganizationAddArea;
