import React, {useEffect, useState} from 'react';
import {PublicWorkCreate} from '@suezenv/public-space-planner-widget';
import '@suezenv/public-space-planner-widget/dist/index.css';
import {connect} from "react-redux";
import 'leaflet/dist/leaflet.css';
import {Organization, UserContract, UserState} from "../../types";
import {OrganizationService} from "../../services/OrganizationService";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router";
import {AppUrls, Services} from "../../constants";
import {UserHelper} from "../../helper/UserHelper";
import {config} from "../../config";
import {PublicSpacePlannerService} from "../../services/PublicSpacePlannerService";
import {AreaService} from "../../services/AreaService";

type IPublicWorkEdit = {
    user: UserState,
    currentContract: UserContract,
}

type GeoServer = {
    url: string,
    workspace: string,
    layer: string,
    authkey: string,
}

const PublicWorkEdit = (props: IPublicWorkEdit) => {
    const { currentContract, user } = props;
    const {heimdallId} = user.info;
    const [organizations, setOrganizations] = useState<Organization[]>();
    const history = useHistory();
    const [geoserver, setGeoserver] = useState<GeoServer>();
    const publicSpacePlannerUser = PublicSpacePlannerService.transTypeUser(user);

    const goToHomeHandle = () => {
        history.push(AppUrls.PUBLIC_WORK_LIST)
    }

    useEffect(() => {
        if (!currentContract) return;
        const organizationId = UserHelper.getServiceOrganization(user, Services.PUBLIC_SPACE_PLANNER);
        setGeoserver({
            authkey: config.geoserverAuthKey as string,
            url: config.geoserverUrl as string,
            layer: `UUID-${organizationId}`,
            workspace: `UUID-${currentContract.id}`,
        });
    }, [currentContract]);

    const fetchOrganizationByUser = () => OrganizationService.getOrganizationsByUserId(heimdallId);
    const {
        data: userOrganizations,
        isSuccess
    } = useQuery(['userOrganizations', heimdallId], fetchOrganizationByUser);

    const fetchUserGeoShape = () => AreaService.getLoggedUserGeoShape(user, currentContract, {
        serviceCode: Services.PUBLIC_SPACE_PLANNER,
        merged: true
    });

    const {
        data: userGeoShape,
    } = useQuery<GeoJSON.Feature>(['userGeoShape', user.info.id], fetchUserGeoShape);

    useEffect(() => {
        if (isSuccess) {
            setOrganizations(userOrganizations?.data);
        }
    }, [userOrganizations]);

    const {workId} = useParams();
    return <div className="w-100 h-100 overflow-auto">
        {organizations &&
        <PublicWorkCreate workId={workId} organizations={organizations} goToHomeHandle={goToHomeHandle}
                          geoserver={geoserver} geoShape={userGeoShape} user={publicSpacePlannerUser}/>
        }
    </div>
};

const mapStateToProps = (state: { contract: { currentContract: UserContract }, user: UserState }) => ({
    user: state.user,
    currentContract: state.contract.currentContract,
});

export default connect(mapStateToProps)(PublicWorkEdit);
