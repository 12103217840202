export { ODIVEA_PORTAL }                            from './ODIVEA_PORTAL';
export { TSMS_PORTAL }                              from './TSMS_PORTAL';
export { KADRAN_PORTAL }                            from './KADRAN_PORTAL';
export { TSMD_PORTAL }                              from './TSMD_PORTAL';
export { PHAREENLIGNE_PORTAL }                      from './PHAREENLIGNE_PORTAL';
export { REPORT_TRENDS }                            from './REPORT_TRENDS';
export { SIGNAL_NOTIFY }                            from './SIGNAL_NOTIFY';
export { VA_EXTERNAL_SITE_SIGRENEA }                from './VA_EXTERNAL_SITE_SIGRENEA';
export { VA_EXTERNAL_SIG_SERAMM }                   from './VA_EXTERNAL_SIG_SERAMM';
export { VA_EXTERNAL_BAIGNADE_WATER_QUALITY }       from './VA_EXTERNAL_BAIGNADE_WATER_QUALITY';
export { VA_EXTERNAL_SYNOPTIQUE_UDB }               from './VA_EXTERNAL_SYNOPTIQUE_UDB';
export { VA_EXTERNAL_SYNOPTIQUE_UDE }               from './VA_EXTERNAL_SYNOPTIQUE_UDE';
export { VA_EXTERNAL_SERAMM_SITE_PUBLICATION }      from './VA_EXTERNAL_SERAMM_SITE_PUBLICATION';
export { VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS }   from './VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS';
export { VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT }       from './VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT';
export { VA_EXTERNAL_SITE_MYSIG }                   from './VA_EXTERNAL_SITE_MYSIG';
export { VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU }       from './VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU';
export { VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP }       from './VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP';
export { VA_EXTERNAL_SITE_AQUACALC }                from './VA_EXTERNAL_SITE_AQUACALC';
export { VA_EXTERNAL_SITE_AQUADVANCED_WW }          from './VA_EXTERNAL_SITE_AQUADVANCED_WW';
export { VA_EXTERNAL_SITE_AQUADVANCED_WN }          from './VA_EXTERNAL_SITE_AQUADVANCED_WN';
export { VA_EXTERNAL_SITE_AQUADVANCED_UD }          from './VA_EXTERNAL_SITE_AQUADVANCED_UD';
export { VA_EXTERNAL_SITE_AQUADVANCED_PLANT }       from './VA_EXTERNAL_SITE_AQUADVANCED_PLANT';
export { VA_EXTERNAL_SITE_SHAREPOINT_KADRAN }       from './VA_EXTERNAL_SITE_SHAREPOINT_KADRAN';

export const existingServiceForms = [
    'ODIVEA_PORTAL',
    'TSMS_PORTAL',
    'TSMD_PORTAL',
    'PHAREENLIGNE_PORTAL',
    'REPORT_TRENDS',
    'SIGNAL_NOTIFY',
    'VA_EXTERNAL_SITE_SIGRENEA',
    'VA_EXTERNAL_SIG_SERAMM',
    'VA_EXTERNAL_BAIGNADE_WATER_QUALITY',
    'VA_EXTERNAL_SYNOPTIQUE_UDB',
    'VA_EXTERNAL_SYNOPTIQUE_UDE',
    'VA_EXTERNAL_SERAMM_SITE_PUBLICATION',
    'VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS',
    'VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT',
    'VA_EXTERNAL_SITE_MYSIG',
    'VA_EXTERNAL_SITE_AQUADVANCED_PLANT',
    'VA_EXTERNAL_SITE_AQUADVANCED_UD',
    'VA_EXTERNAL_SITE_AQUADVANCED_WW',
    'VA_EXTERNAL_SITE_AQUADVANCED_WN',
    'VA_EXTERNAL_SITE_AQUACALC',
    'VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU',
    'VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP',
    'KADRAN_PORTAL',
    'VA_EXTERNAL_SITE_SHAREPOINT_KADRAN'
];
