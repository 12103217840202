import { Action } from "../constants";

const industrialSiteReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Action.INDUSTRIAL_SITE:
            return { ...state, currentIndustrialSite: action.payload };
        default:
            return state;
    }
};

export default industrialSiteReducer;
