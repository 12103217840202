import React, {MutableRefObject, ReactNode, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from "react-router";
import {SzModal} from "@suezenv/react-theme-components";
import Header from '../elements/header';
import Loading from '../elements/loading';
import NavigationDashboard from '../navigationDashboard';
import LoadingPage from '../../pages/loadingPage';
import UserPreferencesModal from "../UserPreferencesModal";
import {RightsHelper} from "../../helper/RightsHelper";
import SzNewSideMenu from "../elements/SzNewSideMenu";
import {useMatomoTracker} from "../../hooks/useMatomoTracker";
import './navigation.scss';
import {UserContract} from "../../types";
import {MenuItem, MenuItems} from '../../model/MenuItems';
import {UserHelper} from 'helper/UserHelper';

type INavigationProps = RouteComponentProps & {
    children: ReactNode;
    isError: boolean;
    loading: Boolean;
    logged: Boolean;
    user: any;
    contract: UserContract;
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
    const {children, user, location, history, contract} = props;
    const {t} = useTranslation();
    const [showOperatorMenu, setShowOperatorMenu] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [modalShow, setModalShow] = useState(false);
    const [menu, setMenu] = useState<MenuItem[]>([]);
    useMatomoTracker(contract);

    const closeMenuHandler = () => {
        setShowOperatorMenu(false);
    }

    function useCloseDashboardMenu(ref: MutableRefObject<any>) {
        useEffect(() => {
            function handleCLick(event: any) {
                if (ref.current
                    && !ref.current.contains(event.target)
                    && !event.target.closest(".nav-dashboard-modules")
                    && !event.target.closest(".nav-dashboard-module-dropdown-button")
                ) {
                    setShowOperatorMenu(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mouseup", handleCLick);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mouseup", handleCLick);
            };
        }, [ref]);
    }

    const initMenu = () => {
        const menuItems = MenuItems({history, location, showOperatorMenu, setShowOperatorMenu, t})
        let userAdminRoles = UserHelper.getAdminRoles(user)

        /*
         * Menu item is displayed if:
         * - item have no roles
         * - one item's role is not in user's services (admin role)
         * - user have one item's role in his current contract's services
         */
        return menuItems
            .filter(menuItem => {
                let menuItemRoles = menuItem?.roles
                let haveAdminRoles = menuItemRoles?.filter((userRole) => userAdminRoles.includes(userRole))

                return (
                    !menuItemRoles?.length
                    || haveAdminRoles?.length
                    || RightsHelper.isGrantedForCurrentContract(user, contract, menuItemRoles)
                )
            })
    };

    useEffect(() => {
        setMenu(initMenu());
    }, [user, location.pathname, showOperatorMenu]);

    const wrapperRef = useRef(null);
    useCloseDashboardMenu(wrapperRef);

    return props.logged || props.isError ? (
        <>
            <Header
                isError={props.isError}
                setModalShow={setModalShow}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
            <div className="d-flex flex-row flex-fill main-wrapper">
                <SzNewSideMenu items={menu} setShowMenu={setShowMenu} showMenu={showMenu}/>
                <main className="container-fluid flex-grow-1 d-flex flex-column">
                    {props.loading && <Loading/>}
                    <div className="row flex-fill position-relative scrollable h-100" ref={wrapperRef}>
                        <div className="col-12" ref={wrapperRef}>
                            {showOperatorMenu &&
                                (
                                    <NavigationDashboard
                                        closeMenuHandler={closeMenuHandler}
                                        user={user}
                                    />
                                )
                            }
                            {children}
                        </div>
                    </div>
                    <SzModal
                        className={"modal-centered"}
                        title={t('settings.dashboard')}
                        show={modalShow}
                        size="xl"
                        backdrop={true}
                        centered={true}
                        handleClose={() => {
                            setModalShow(false)
                        }}
                    >
                        <UserPreferencesModal/>
                    </SzModal>
                </main>
            </div>
        </>
    ) : (
        <LoadingPage/>
    );
};

export default withRouter(Navigation);
