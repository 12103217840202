import React, {useEffect, useState} from 'react';
import '@suezenv/signal-widget/dist/index.css';
import 'leaflet/dist/leaflet.css';
import {ThingsBoardService} from "../../services/ThingsBoardService";
import {config} from "../../config";
import { useQuery } from "react-query";
import {Dashboard} from "../../types";

const ThingsBoardDashboardPage = () => {
    const [dashboardUri, setDashboardUri] = useState<string>(null);
    const [accessToken, setAccessToken] = useState<string>(null);

    const fetchUserToken = () => ThingsBoardService.getUserToken();
    const { data: userToken } = useQuery<any>(['userToken'], fetchUserToken);
    const fetchDashboards = () => ThingsBoardService.getDashboards();
    const { data: dashboard } = useQuery<Dashboard[]>(['dashboard'], fetchDashboards);

    useEffect(() => {
        if (dashboard) {
            if (dashboard.length === 1) {
                // setDashboardUri("/dashboards/" + dashboard[0].dashboardGroup + "/" + dashboard[0].id.id)
                setDashboardUri("/dashboard/" + dashboard[0].id.id)
            } else {
                setDashboardUri("/dashboards/")
            }
        }
    }, [dashboard]);

    useEffect(() => {
        if (userToken) {
            setAccessToken(userToken.token);
        }
    }, [userToken]);

    return (
        <div className={'row'}>
            <div className={'col-12'}>
                {dashboardUri &&
                    <iframe
                        title={"ThingsBoard"}
                        src={config.portalThingsboardUrl + dashboardUri + "?accessToken=" + accessToken}
                        allowFullScreen={true}
                        style={{height: '92vh', width: '100%'}}
                    />
                }
            </div>
        </div>
    );
}

export default ThingsBoardDashboardPage;
