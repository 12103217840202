import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ApplicationConfigurationFormProps } from "../../../../types";
import { SimpleConfiguration } from "./SimpleConfiguration";

const serviceName = "VA_EXTERNAL_SITE_AQUADVANCED_PLANT";


export const VA_EXTERNAL_SITE_AQUADVANCED_PLANT = (props: ApplicationConfigurationFormProps) => {
    return <SimpleConfiguration {...props} serviceName={serviceName} />;
}
